import _IntlTelInput2 from "./components/IntlTelInput";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _IntlTelInput = _interopRequireDefault(_IntlTelInput2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var _default = _IntlTelInput.default;
exports.default = _default;
export default exports;
export const __esModule = exports.__esModule;